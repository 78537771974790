import * as yup from "yup";
import { VALIDATION_MESSAGES } from "../validationErrors";

/**
 * OTP Schema for Validation
 * @type {*}
 */

export const OTPValidationSchema: any = yup.object().shape({
    otp: yup.string().min(6, "OTP must be 6 characters long").max(6, "OTP must be 6 characters long").required().required(VALIDATION_MESSAGES.OTP_REQUIRED).label("OTP")
});